import "../server/node-loader";
import {
  VStack,
  HStack,
  Spacer,
  Button,
  Text,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Tag,
  Icon,
  Flex,
  Grid,
  MenuItem,
} from "@chakra-ui/react";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { BiChevronDown, BiGitRepoForked } from "react-icons/bi";
import useSWR from "swr";
import { QonduitWorkflow } from "../server/workflowBuilder";
import { useClient } from "../utils/QonduitClient";
import SettingsWorkflowMenu from "./menu/SettingsWorkflowMenu";
import CreateWorkflowModal from "./modal/CreateWorkflowModal";
import axios from "axios";
import env from "../utils/env";
import Cookies from "js-cookie";
import { menuItemStyle, menuListStyle } from "../utils/theme";

const sortMenu = [
  "Latest Created",
  "Oldest Created",
  "A to Z",
  "Z to A",
  "Active first",
  "Inactive first",
];

function HomePage() {
  const client = useClient();
  const router = useRouter();
  const [selectSortOption, setSelectSortOption] = React.useState(sortMenu[0]);

  const { data: version } = useSWR("version", async (params) => {
    const resp = await axios.get(`${env.NEXT_PUBLIC_API_ENDPOINT}/${params}`, {
      headers: {
        "x-qonduit-admin-secret": Cookies.get("qonduit-access-token"),
      },
    });
    return resp.data.version;
  });

  const workflows = useSWR([client], async () => {
    const resp = await client.getWorkflows();
    const allWorkflows = resp.data as QonduitWorkflow[];
    const allWorkflowsWithStatus = allWorkflows.map(async (workflow) => {
      const { data } = await client.getWorkflowStatus(workflow.id);
      return { ...workflow, isActive: !!data.isActive };
    });
    return Promise.all(allWorkflowsWithStatus);
  });
  const handleSelectWorkflow = React.useCallback((workflow) => {
    router.push(`workflows/${workflow.id}`);
  }, []);

  const sortedWorklows = React.useMemo(() => {
    if (!workflows.data) return [];
    const unSortedWorkflows = [...workflows.data];
    if (selectSortOption == "A to Z" || selectSortOption == "Z to A") {
      unSortedWorkflows.sort((a, b) => {
        const fa = a.title[0].toLowerCase();
        const fb = b.title[0].toLowerCase();
        return fa < fb ? -1 : fa > fb ? 1 : 0;
      });
      if (selectSortOption == "Z to A") unSortedWorkflows?.reverse();
    }

    if (
      selectSortOption === "Active first" ||
      selectSortOption === "Inactive first"
    ) {
      unSortedWorkflows.sort((a, b) => {
        return a.isActive ? -1 : a.isActive ? 1 : 0;
      });
      if (selectSortOption === "Inactive first") unSortedWorkflows?.reverse();
    }

    if (
      selectSortOption === "Oldest Created" ||
      selectSortOption === "Latest Created"
    ) {
      unSortedWorkflows.sort((a, b) => {
        return a.createAt - b.createAt;
      });
      if (selectSortOption === "Latest Created") unSortedWorkflows?.reverse();
    }
    return unSortedWorkflows;
  }, [selectSortOption, workflows.data]);
  return (
    <VStack
      alignItems="normal"
      spacing={sortedWorklows?.length ? 4 : 0}
      height={sortedWorklows?.length ? "100%" : "94vh"}
    >
      <HStack>
        <VStack>
          <Text fontSize="30px" lineHeight="20px" fontWeight="600">
            Pipeline
          </Text>
          <Text fontSize="10px" color="grey" textAlign="left">
            {version ? `Version: ${version}` : ""}
          </Text>
        </VStack>
        <Spacer />
        <CreateWorkflowModal />
      </HStack>
      {sortedWorklows?.length ? (
        <>
          <HStack color="#6F809A" paddingTop="10px">
            <Text>
              Showing{" "}
              <span style={{ fontWeight: 600, color: "#001E4D" }}>
                {sortedWorklows.length}
              </span>{" "}
              Pipeline
            </Text>
            <Spacer />
            <Text fontSize={"reg"}>Sort By: </Text>
            <Menu placement={"bottom"}>
              <MenuButton
                width="141px"
                size="sm"
                fontSize={"reg"}
                as={Button}
                variant="unstyled"
                rightIcon={<BiChevronDown />}
                paddingRight={2}
                color="#001E4D"
                display="flex"
                _focus={{ borderWidth: "0px" }}
              >
                {selectSortOption}
              </MenuButton>
              <MenuList style={menuListStyle}>
                {sortMenu.map((option) => (
                  <MenuItem
                    justifyContent="start"
                    variant="ghost"
                    size="sm"
                    onClick={() => setSelectSortOption(option)}
                    _hover={menuItemStyle}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
          <Grid
            templateColumns="repeat(3, 1fr)"
            columnGap="24px"
            rowGap="24px"
            alignSelf="center"
            paddingBottom="50px"
          >
            {sortedWorklows.map((workflow, i) => (
              <VStack
                spacing={7}
                key={i}
                borderRadius="4px"
                border="1px solid #E6E9EC"
                padding="24px"
                width="360px"
                fontSize="12px"
                alignItems="normal"
                _hover={{
                  border: "1px solid #4D93FF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleSelectWorkflow(workflow);
                }}
              >
                <Flex>
                  {workflow.isActive ? (
                    <Tag size="sm" colorScheme="blue" color="#004DC4">
                      Active
                    </Tag>
                  ) : (
                    <Tag size="sm" colorScheme="gray" color="#6F809A">
                      Inactive
                    </Tag>
                  )}
                  <Spacer />
                  <SettingsWorkflowMenu workflow={workflow} />
                </Flex>
                <HStack spacing={4}>
                  <Flex
                    width="40px"
                    height="40px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="8px"
                    backgroundColor="#F9F9FA"
                  >
                    <Icon
                      fontSize={20}
                      as={BiGitRepoForked}
                      style={{ color: "#A6B0C1" }}
                    />
                  </Flex>
                  <VStack spacing={0.5} alignItems="normal">
                    <Text fontWeight="600" fontSize="14px">
                      {workflow.title}
                    </Text>
                    {/* <Text color="#6F809A">Edited 2 Minutes Ago</Text> */}
                  </VStack>
                </HStack>
                {/* <VStack alignItems="normal">
                  <Text>{workflow.desc ? workflow.desc : <br />}</Text>
                  <Flex>
                    <Text color="#6F809A">Trigger</Text>
                    <Spacer />
                    <HStack>
                      <Icon fontSize={14} as={BiGitRepoForked} />
                      <Icon fontSize={14} as={BiGitRepoForked} />
                      <Icon fontSize={14} as={BiGitRepoForked} />
                    </HStack>
                  </Flex>
                  <Flex>
                    <Text color="#6F809A">Last run</Text>
                    <Spacer />
                    <Text>3 days ago</Text>
                  </Flex>
                  <Flex>
                    <Text color="#6F809A">Updated by</Text>
                    <Spacer />
                    <Text>Wilson Levin</Text>
                  </Flex>
                </VStack> */}
              </VStack>
            ))}
          </Grid>
        </>
      ) : (
        workflows.data?.length === 0 && (
          <>
            <Box
              width="100%"
              height="100%"
              spacing={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <HStack>
                <Image
                  htmlWidth="372px"
                  src="./workflow.png"
                  alt="Workflow Example"
                />
                <VStack
                  alignItems="normal"
                  width="468px"
                  alignSelf="flex-start"
                >
                  <Text
                    fontWeight="600"
                    fontSize="20px"
                    lineHeight="28px"
                    color="#A6B0C1"
                  >
                    You haven't created a pipeline yet
                  </Text>
                  <Text fontSize="14px" lineHeight="20px">
                    Pipeline is a wonderful tools to automate any of your
                    business needs, from tinkering your csv or excel file, to
                    building your Qore data always in sync with other tools.
                  </Text>
                  <Text fontSize="14px" lineHeight="20px">
                    Click “Create pipeline” to start
                  </Text>
                </VStack>
              </HStack>
            </Box>
          </>
        )
      )}
    </VStack>
  );
}

export default HomePage;
