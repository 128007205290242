import { Container } from '@chakra-ui/react'
import React from 'react'
import HomePage from '../components/HomePage'

function IndexPage() {
  return (
    <Container maxW="6xl" paddingTop="40px">
      <HomePage />
    </Container>
  )
}

export default IndexPage
