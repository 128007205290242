import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ButtonGroup,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { getDefinitions } from "../../server/definitions";
import { useWorkflowBuilder } from "../../server/WorkflowBuilderProvider";
import { useDisclosure, UseDisclosureProps } from "@chakra-ui/hooks";
import { useClient } from "../../utils/QonduitClient";
import { buildProcessorInstance } from "../../server/interfaces";
import { QonduitWorkflow } from "../../server/workflowBuilder";
import { useRouter } from "next/dist/client/router";
import { v4 } from "uuid";
import { useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";

function CreateWorkflowModal() {
  const { handleSubmit, register } = useForm();
  const addWorkflowDisclosure = useDisclosure();
  const client = useClient();
  const router = useRouter();
  const toast = useToast();
  const definitions = React.useMemo(() => Object.values(getDefinitions()), []);
  const { build } = useWorkflowBuilder();
  const handleAddWorkflow = React.useCallback(
    async ({ title, desc }: { title: string; desc: string }) => {
      const instance = buildProcessorInstance(definitions[0]);
      const response = await client.createWorkflow({
        id: v4(),
        title,
        desc,
        instances: {
          [instance.id]: instance,
        },
      });
      const resp = await client.getWorkflow(response.data.id);
      build((builder) => {
        builder.manager.load(resp.data as unknown as QonduitWorkflow);
      });
      router.push(`workflows/${response.data.id}`);
      setTimeout(() => {
        toast({
          description: "Successfully create pipeline",
          isClosable: true,
          status: "success",
        });
      }, 1000);
    },
    [client]
  );
  return (
    <>
      <Button
        leftIcon={<BiPlus />}
        fontSize="14px"
        colorScheme="blue"
        variant="solid"
        onClick={addWorkflowDisclosure.onOpen}
      >
        Create Pipeline
      </Button>
      <Modal
        isOpen={addWorkflowDisclosure.isOpen!}
        onClose={addWorkflowDisclosure.onClose!}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent padding="24px 16px 24px 16px">
          <ModalHeader fontSize="24px">Create new pipeline</ModalHeader>
          <form onSubmit={handleSubmit(handleAddWorkflow)}>
            <ModalBody>
              <VStack spacing={6}>
                <FormControl isRequired>
                  <FormLabel htmlFor="title">Pipeline name</FormLabel>
                  <Input
                    id="title"
                    size="md"
                    fontSize="14px"
                    placeholder="New pipeline"
                    {...register("title", {
                      shouldUnregister: true,
                    })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="desc">Description</FormLabel>
                  <Input
                    id="desc"
                    size="md"
                    fontSize="14px"
                    placeholder="Short description about this pipeline..."
                    {...register("desc", {
                      shouldUnregister: true,
                    })}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center" paddingTop="24px">
              <ButtonGroup width="100%" size="lg" spacing={3}>
                <Button
                  width="50%"
                  onClick={addWorkflowDisclosure.onClose}
                  fontSize="16px"
                >
                  Cancel
                </Button>
                <Button
                  width="50%"
                  colorScheme="blue"
                  type="submit"
                  variant="solid"
                  fontSize="16px"
                >
                  Create
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateWorkflowModal;
